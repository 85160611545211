<template>
  <div
    v-if="campaign"
    class="flex flex-col gap-3 rounded-[30px] bg-white p-3 shadow-md"
  >
    <div
      class="relative h-[264px] w-full overflow-hidden rounded-2xl shadow-md md:h-[417px]"
      :class="[active ? 'bg-navy' : campaign.bgColor]"
    >
      <div
        :class="[{ 'after:absolute after:inset-0 after:bg-black/20': active }]"
      >
        <NuxtImg
          :src="campaign.posterImg"
          sizes="100vw md:30vw"
          :alt="campaign.title"
          class="absolute h-full w-full object-cover transition-all"
          :class="[{ 'opacity-0': !active }, { 'opacity-1': active }]"
        />
      </div>
      <div
        class="relative flex h-full w-full flex-col items-center justify-around"
      >
        <div class="mt-3 flex flex-col items-center">
          <NuxtLink
            :href="`https://campaigns.dollardonationclub.com/reports/${campaign.slug}`"
            target="_blank"
            class="rounded-md"
          >
            <PigPercentValueDisplay
              :value="campaign.impactScore"
              :class="{
                [campaign.bgColor]: active,
                'bg-white/50': !active,
              }"
              class="rounded-md border border-b-0 border-navy"
            />
          </NuxtLink>

          <div
            :class="{
              [campaign.bgColor]: active,
              'bg-white/50': !active,
            }"
            class="max-w-[23ch] rounded-md border border-navy px-3 py-1 text-center text-p2 font-bold uppercase text-navy lg:py-2"
          >
            {{ campaign.title }}
          </div>
        </div>

        <div>
          <NuxtImg
            :src="campaign.icon"
            :alt="campaign.icon"
            :width="120"
            :height="120"
            :class="[{ hidden: active }, { block: !active }]"
            class="w-[80px] md:w-full"
          />
        </div>

        <div
          class="rounded-xl px-3 text-[36px] font-black md:px-5 md:text-[54px]"
          :class="[
            { 'bg-navy text-white': !active },
            { 'bg-white text-navy': active },
          ]"
        >
          $1
        </div>

        <p
          class="z-10 max-w-[24ch] text-pretty text-center font-Kontesa font-bold uppercase"
          :class="[
            { 'text-p1 font-bold text-white md:text-[27px]': active },
            { 'text-p2 text-navy md:text-[21px]': !active },
          ]"
        >
          {{ campaign.dollarImpact }}
        </p>

        <div
          class="absolute bottom-0 z-0 h-10 w-full lg:h-[118px]"
          :class="active ? 'bottom-shade opacity-70' : ''"
        ></div>
      </div>
    </div>
    <!-- buttons -->
    <div v-if="active" class="flex flex-col gap-2 lg:flex-row">
      <button
        type="button"
        :class="[campaign.bgColor]"
        class="basis-1/2 cursor-pointer rounded-3xl border border-navy py-3 shadow-[2px_2px_0_#16114F] md:pb-[27px] md:pt-[25px]"
        @click="playVideo"
      >
        <div
          class="flex items-center justify-center text-p2-mobile font-bold uppercase leading-none -tracking-[0.03em] text-navy lg:text-p1"
        >
          <span>Preview</span>
          <img
            src="/images/icons/play.png"
            class="h-[16px] w-[20px] lg:h-[19px] lg:w-[23px]"
          />
        </div>
      </button>

      <NuxtLink
        :href="`/campaigns/${campaign.slug}`"
        :class="[campaign.bgColor]"
        class="basis-1/2 rounded-3xl border border-navy py-4 text-center text-p2-mobile font-bold uppercase leading-none -tracking-[0.03em] text-navy shadow-[2px_2px_0_#16114F] md:pb-[27px] md:pt-[25px] lg:text-p1"
        >View Project</NuxtLink
      >
    </div>
    <VideoPlayerModal
      :id="isMobile ? campaign.shortVideoIdMobile : campaign.shortVideoId"
      ref="video"
    />
  </div>
</template>

<script setup lang="ts">
import { useCampaign } from "~/domains/campaigns/composables";
import VideoPlayerModal from "~/components/VideoPlayer/Modal.vue";

interface ProjectsCardProps {
  campaignId: number;
  active: boolean;
}

const props = defineProps<ProjectsCardProps>();

const { campaign } = useCampaign(props.campaignId);

const video = ref<InstanceType<typeof VideoPlayerModal> | null>(null);
const playVideo = () => {
  video.value?.play();
};

const breakpoints = useBreakpoints({
  sm: useTheme().screens.sm,
});
const isMobile = computed(() => breakpoints.smaller("sm").value);
</script>

<style scoped>
.ocean {
  @apply bg-ocean;
}

.ocean-low {
  @apply bg-ocean-low;
}

.yellow {
  @apply bg-yellow;
}

.yellow-low {
  @apply bg-yellow-low;
}

.success-medium {
  @apply bg-success-medium;
}

.success-medium-low {
  @apply bg-success-low;
}

.bottom-shade {
  background: linear-gradient(180deg, rgba(100, 100, 100, 0) 0%, #000000 100%);
}
</style>
