<template>
  <Swiper
    :slides-per-view="'auto'"
    :modules="[SwiperMousewheel, SwiperKeyboard]"
    :centered-slides="true"
    :initial-slide="1"
    :mousewheel="{
      forceToAxis: true,
      thresholdDelta: 25,
      thresholdTime: 300,
      sensitivity: 0.1,
    }"
    :keyboard="true"
    :long-swipes="true"
    :slide-to-clicked-slide="true"
    :touch-ratio="0.5"
    @swiper="onSwiper"
    @slide-change="updateCard"
    @mouseover="onMouseOver"
    @mouseleave="onMouseLeave"
  >
    <SwiperSlide
      v-for="(campaign, index) in campaigns"
      :key="campaign.id"
      class="max-w-[230px] px-2 py-1 md:max-w-[364px] md:px-4 md:py-2"
      :class="{
        'opacity-30': index + 2 <= activeIndex || index - 2 >= activeIndex,
      }"
    >
      <ProjectsCard
        :active="index === activeIndex"
        :campaign-id="campaign.id"
      />
    </SwiperSlide>
  </Swiper>
</template>

<script setup lang="ts">
import { useQuery } from "@tanstack/vue-query";
import type { Swiper } from "swiper/types";
import { campaignQueries } from "~/domains/campaigns/queries";

const { suspense, data: campaigns } = useQuery({
  ...campaignQueries.list(),
  suspense: true,
});

await suspense();

const swiperInstance = ref<Swiper | null>(null);
const onSwiper = (swiper: Swiper) => {
  swiperInstance.value = swiper;
};

const activeIndex = ref(Math.floor((campaigns.value?.length ?? 0) / 2 - 1));
const updateCard = (slider: Swiper) => {
  // locks any swiping to the next card/slide at most
  if (slider.activeIndex > activeIndex.value) {
    activeIndex.value += 1;
  } else if (slider.activeIndex < activeIndex.value) {
    activeIndex.value -= 1;
  }
};

watchEffect(() => {
  console.log(activeIndex.value);
});

function onMouseOver() {
  document.body.classList.add("overscroll-x-none");
}

function onMouseLeave() {
  document.body.classList.remove("overscroll-x-none");
}
</script>
